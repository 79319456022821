<template>
  <v-container fluid class="content-wrapper mb-6">
    <div class="d-flex flex-column mb-5">
      <div class="d-flex flex-row justify-start align-center">
        <h4 class="fw-600">{{ $t("SigninHotelFacility.title") }}</h4>
        <v-btn icon class="ml-3" @click="initAll()" :class="loadingFacilityData && 'animate__spining'" :disabled="loadingFacilityData">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
      <!-- <h4 class="fw-600">{{ $t("SigninRoomDetails.title") }}</h4> -->
      <span class="subtitle">{{ $t("SigninHotelFacility.subtitle") }}</span>
    </div>
    <v-row row wrap class="mt-0">
      <v-col cols="12">
        <div v-if="loadingFacilityData" class="card card-shadow pa-5">
          <v-skeleton-loader
            type="list-item-two-line, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </div>
        <div v-else class="card card-shadow pa-5">
          <h3 class="title-sm-2">{{ $t("SigninHotelFacility.hottitle") }}</h3>
          <span class="textstyle subtitle">
            {{ $t("SigninHotelFacility.hotlabel1") }} "{{
              $t("SigninHotelFacility.hotlabel2")
            }}" {{ $t("SigninHotelFacility.hotlabel3") }} "{{
              $t("SigninHotelFacility.hotlabel4")
            }}" {{ $t("SigninHotelFacility.hotlabel5")
            }}{{ $t("SigninHotelFacility.hotlabel6") }}
          </span>
          <v-row row wrap class="mt-3">
            <template v-for="(item, index) in itemHotelFacility">
              <v-col :key="item._id" cols="12" sm="12" md="6" lg="6">
                <v-card outlined class="pl-3" style="height: 100%">
                  <v-row row wrap>
                    <v-col
                      cols="7"
                      class="my-3 d-flex flex-column align-start justify-center"
                    >
                      <div class="d-flex justify-content-between mb-1">
                        <span class="textstyle mt-0">{{ item.facilityname }}</span>
                      </div>
                      <div class="form-group mb-0" v-if="item.active">
                        <div
                          class="dropdown select"
                          @click="getfocus(`selectfree_${item._id}`)"
                          @mouseleave="mouseOver(`selectfree_${item._id}`)"
                        >
                          <a
                            data-toggle="dropdown"
                            data-display="static"
                            :id="`selectfree_${item._id}`"
                          >
                            <v-autocomplete
                              v-model="item.free"
                              :items="itemServiceCharge"
                              item-text="name"
                              item-value="value"
                              label=""
                              :placeholder="
                                $t('SigninHotelFacility.select_servicecharge')
                              "
                              :no-data-text="$t('SignupStep.nodata')"
                              color="pink"
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                              @change="selectServiceChargeFunc(item, item.free)"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content
                                  @mouseenter="getfocus(`selectfree_${item._id}`)"
                                  @mouseleave="mouseOver(`selectfree_${item._id}`)"
                                >
                                  <v-list-item-title
                                    v-html="data.item.name"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </a>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="5" justify="end">
                      <div class="d-flex">
                        <v-radio-group row v-model="item.active">
                          <v-radio
                            :label="$t('SigninHotelFacility.btn_yes')"
                            :value="true"
                            color="#1e9ff2"
                            @change="changeToActiveFunc(item)"
                          ></v-radio>
                          <v-radio
                            :label="$t('SigninHotelFacility.btn_no')"
                            :value="false"
                            color="#1e9ff2"
                          >
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="d-flex flex-row align-center justify-space-between"></div>
              </v-col>
            </template>
          </v-row>
          <v-divider></v-divider>
          <h3 class="title-sm mb-0">
            {{ $t("SigninHotelFacility.builddetail") }}
          </h3>
          <v-row row wrap>
            <v-col cols="12" sm="12" md="6" lg="6">
              <div class="form-group">
                <label class="light">
                  {{ $t("SigninHotelFacility.builddetail_roomnum") }}
                </label>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  name=""
                  :placeholder="$t('SigninHotelFacility.builddetail_roomnum')"
                  @keypress="isNumber($event)"
                  v-model="build_roomnum"
                />
              </div>
              <div class="form-group">
                <label class="light">
                  {{ $t("SigninHotelFacility.builddetail_floornum") }}
                </label>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  name=""
                  :placeholder="$t('SigninHotelFacility.builddetail_floornum')"
                  @keypress="isNumber($event)"
                  v-model="build_buildnum"
                />
              </div>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col cols="6" sm="6" md="2" lg="2">
              <v-btn
                depressed
                dark
                block
                color="#666ee8"
                style="text-transform: capitalize !important"
                :disabled="!cansavehotelfacility"
                @click="dialogConfirmSave = true"
              >
                <span>{{ $t("SigninHotelFacility.btn_save") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogConfirmSave" persistent max-width="360">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >{{ $t("Alert.doyouwanttosave") }}</v-card-title
        >
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            depressed
            dark
            color="#e83e8c"
            class="pa-3 mr-3"
            @click="saveHotelFacilityFunc()"
            >{{ $t("SigninHotelFacility.btn_save") }}</v-btn
          >
          <v-btn color="pink" class="pa-3" outlined @click="dialogConfirmSave = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import _ from "lodash";

export default {
  name: "SigninHotelFacilitiesScreen",
  metaInfo: {
    title: "Hotel Facility",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        this.initAll();
        next(false);
      }
    } else next();
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    propertyData: null,
    itemServiceCharge: [],
    itemCategoryFacility: [],
    itemAreaFacility: [],
    itemHotelFacility: [],
    loading: false,
    loadingSave: false,
    cansavehotelfacility: false,
    build_roomnum: 1,
    build_buildnum: 1,
    itemsOldFacilities: [],
    value_element: "",
    dialogConfirmSave: false,
    loadingFacilityData: false,
    oldData: {},
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    const self = this;
    EventBus.$on("refreshRightPhoto", self.initAll);
    self.colorHover();
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      // console.log('ttt', document.querySelector('.hover_btn'))
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    initAll() {
      const self = this;
      self.itemServiceCharge = [
        {
          _id: "000000",
          name: self.$t("SignupStep4.free"),
          value: true,
        },
        {
          _id: "000000",
          name: self.$t("SignupStep4.charge"),
          value: false,
        },
      ];
      self.initPropertyData();
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempFacility = [];
      var tempOldFacility = [];
      self.loadingFacilityData = true;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          temp?.facilities?.map((el) => {
            if (el?._id && el?.facility?._id) {
              // console.log(el);
              tempFacility.push({
                ...el,
                facilityname: el?.facility?.value || "",
                facilityid: el?.facility?._id || "",
                facility: el?.facility?._id || "",
                active: el?.active || false,
                free: el?.free,
              });
              tempOldFacility.push({
                ...el,
                facilityname: el?.facility?.value || "",
                facilityid: el?.facility?._id || "",
                facility: el?.facility?._id || "",
                active: el?.active || false,
                free: el?.free,
              });
            }
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.itemsOldFacilities = tempFacility;
        // console.log("propertyData: ", self.propertyData);
        self.itemHotelFacility = tempFacility || [];
        self.build_roomnum = self?.propertyData?.total_rooms || 1;
        self.build_buildnum = self?.propertyData?.total_floors || 1;
        self.oldData = {
          itemHotelFacility: tempOldFacility,
          build_roomnum: temp?.total_rooms || 1,
          build_buildnum: temp?.total_floors || 1,
        };
        if (self?.itemHotelFacility?.length) self.cansavehotelfacility = true;
        self.loadingFacilityData = false;
      }
    },
    toggleRoomsFunc(item) {
      const self = this;
      console.log(item);
      console.log(item.allrooms);
      console.log(self.itemHotelFacility);
    },
    gobackFunc() {
      this.loading = true;
      EventBus.$emit("goBackPage");
    },
    async saveHotelFacilityFunc() {
      const self = this;
      self.dialogConfirmSave = false;
      self.loading = true;
      self.cansavehotelfacility = false;
      var temp = [];
      console.log("save", self.itemHotelFacility);
      // var num = 0;
      const obj = {
        facilities: self.itemHotelFacility,
      };
      // console.log('facilities: ', obj)
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step4?property_id=${self.propertyID}&next=${1}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.dialogConfirmSave = false;
          EventBus.$emit("endloading");
          self.saveBuildingInfo();
        }
      } catch (error) {
        self.dialogConfirmSave = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.loading = false;
        self.cansavehotelfacility = true;
        self.initAll();
      }
    },
    async saveBuildingInfo() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        total_rooms: self.build_roomnum || 0,
        total_floors: self.build_buildnum || 0,
      };

      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/general_info?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.dialogConfirmSave = false;
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        self.dialogConfirmSave = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.loading = false;
        self.cansavehotelfacility = true;
        self.initAll();
      }
    },
    selectServiceChargeFunc(item, value) {
      const self = this;
    },
    changeToActiveFunc(item) {
      const self = this;
      if (item.free == null || item.free == "undefined") item.free = true;
    },
    checkChangeData() {
      const self = this;
      var checkchangefacilities = false;
      var cantleavethispage = true;

      self.itemHotelFacility?.map((el) => {
        const findSameItem = self.oldData.itemHotelFacility?.find(
          (p) => p._id == el?._id
        );
        if (findSameItem?.active !== el?.active) checkchangefacilities = true;
        else if (findSameItem?.free !== el?.free) checkchangefacilities = true;
      });

      if (
        checkchangefacilities ||
        self.build_roomnum !== self.oldData.build_roomnum ||
        self.build_buildnum !== self.oldData.build_buildnum
      ) {
        cantleavethispage = true;
      } else cantleavethispage = false;
      return cantleavethispage;
    },
  },
};
</script>

<style>
.v-label {
  margin-bottom: 0 !important;
}
</style>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}
</style>
